@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-BlackItalic.woff2?v=1692109134') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-BlackItalic.woff?v=1692109134') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Black.woff2?v=1692109168') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Black.woff?v=1692109168') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Bold.woff2?v=1692109201') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Bold.woff?v=1692109201') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Thin.woff2?v=1692109235') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Thin.woff?v=1692109235') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Light.woff2?v=1692109268') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Light.woff?v=1692109268') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-LightItalic.woff2?v=1692109309') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-LightItalic.woff?v=1692109308') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-BookItalic.woff2?v=1692109337') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-BookItalic.woff?v=1692109338') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-MediumItalic.woff2?v=1692109367') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-MediumItalic.woff?v=1692109366') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Medium.woff2?v=1692109393') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Medium.woff?v=1692109393') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Book.woff2?v=1692108926') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Book.woff?v=1692108926') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-ThinItalic.woff2?v=1692109419') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-ThinItalic.woff?v=1692109419') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-BoldItalic.woff2?v=1692109445') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-BoldItalic.woff?v=1692109445') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Ultra.woff2?v=1692109476') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-Ultra.woff?v=1692109476') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-XLightItalic.woff2?v=1692109519') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-XLightItalic.woff?v=1692109519') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-XLight.woff2?v=1692109545') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-XLight.woff?v=1692109545') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-UltraItalic.woff2?v=1692109572') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0741/6132/4314/files/Gotham-UltraItalic.woff?v=1692109572') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

